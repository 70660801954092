import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { isUserLoggedIn } from '@/auth/utils'
import pages from './routes/pages'
import dashboardRouter from '@/router/routes/dashboard.router'
import proxyRouter from "@/router/routes/proxy.router";
import invoiceRouter from "@/router/routes/invoice.router";
import useJwt from '@/auth/jwt/useJwt'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'dashboard' } },
    ...pages,
    ...dashboardRouter,
    ...proxyRouter,
    ...invoiceRouter,
    {
      path: '*',
      redirect: 'error-404'
    }
  ]
})

router.beforeEach((to, _, next) => {
  const routersNotRedirect = ['/login', '/register']
  let isLoggedIn = isUserLoggedIn()

  if (!routersNotRedirect.includes(to.path)) {
    return next()
  } else {
    useJwt.checkValidToken().then(response => {
      isLoggedIn = isLoggedIn && response.data.data['isValidToken']
    })
        .catch(() => {
          isLoggedIn = false
        })
        .then(() => {
          if (routersNotRedirect.includes(to.path) && isLoggedIn) {
            return next('/')
          } else if (!routersNotRedirect.includes(to.path) && !isLoggedIn) {
            return next({ name: 'auth-login' })
          }
          next()
        })
  }
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
