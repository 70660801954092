import utilsService from "@/mixins/utils/axios/utils.service"
const statisticEndpoint = `${process.env.VUE_APP_BASE_URL}/admin/statistic`

export default {
    getIncomeStatistic (months) {
        if (months !== undefined) {
            return utilsService.getRequest(`${statisticEndpoint}/total-income?months=${months}`);
        } else {
            return utilsService.getRequest(`${statisticEndpoint}/total-income`);
        }
    },
    getCurrentProfitEstimate () {
        return utilsService.getRequest(`${statisticEndpoint}/profit-estimate`);
    }
}
