// noinspection JSValidateTypes

import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  methods: {
    runApi (apiMethod,
            onSuccess = undefined,
            onError = undefined,
            onFinally = undefined,
            successNotification = false,
            customSuccessNotification = null,
            isShowErrorNotification = true) {
      const self = this
      apiMethod.then(response => {
        if (response.data.status === 'success') {
          if (successNotification) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: self.$t('axios.title.success'),
                icon: 'CheckCircleIcon',
                variant: response.data.status,
                text: customSuccessNotification ? customSuccessNotification : (self.$te(`axios.success.${response.data.message}`) ? self.$t(`axios.success.${response.data.message}`) : response.data.message)
              }
            })
          }
          if (typeof (onSuccess) === 'function') {
            onSuccess(response.data)
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: self.$t('axios.title.warning'),
              icon: 'AlertCircleIcon',
              variant: response.data.status,
              text: self.$te(`axios.error.${response.data.message}`) ? self.$t(`axios.error.${response.data.message}`) : response.data.message
            }
          })
          if (typeof (onError) === 'function') {
            onError(response.data)
          }
        }
      })
          .catch(error => {
            console.error(error)
            if (isShowErrorNotification) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: self.$t('axios.title.error'),
                  icon: 'XCircleIcon',
                  variant: error.response.data.status,
                  text: self.$te(`axios.error.${error.response.data.message}`) ? `${error.response.data.data ? `${self.$te(`axios.fields.${error.response.data.data}`) ? self.$t(`axios.fields.${error.response.data.data}`) : error.response.data.data}` : ''} ${self.$t(`axios.error.${error.response.data.message}`)}` : error.response.data.message
                }
              })
            }
            if (typeof (onError) === 'function') {
              onError(error.response.data)
            }
            if (error.response.status === 401 && window.location.pathname !== '/login') {
              window.location.href = '/login'
            }
          })
          .then(() => {
            {
              this.$loading.hide()
              if (typeof (onFinally) === 'function') {
                onFinally()
              }
            }
          })
    }
  }
}
