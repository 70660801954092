<template>
  <vue-apex-charts
      type="donut"
      height="400"
      :options="chartOptions"
      :series="series"
  />
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import InputHelper from "@/mixins/utils/inputHelper";

export default {
  name: 'ProfitEstimate',
  components: {
    VueApexCharts
  },
  mixins: [InputHelper],
  props: {
    data: {
      type: Object,
      default: {},
    }
  },
  computed: {
    series () {
      let self = this
      let item = {}
      if ([null, undefined].includes(this.data)) {
        item = {
          vps: { profit: 0 },
          livestream: { profit: 0 },
          proxy: { profit: 0 }
        }
      } else {
        item = this.data
      }
      let totalProfit = item['vps']['profit'] + item['livestream']['profit'] + item['proxy']['profit']
      if (totalProfit === 0) {
        return [0, 0, 0]
      }

      return [
        parseFloat((item['vps']['profit'] / totalProfit * 100).toFixed(2)),
        parseFloat((item['livestream']['profit'] / totalProfit * 100).toFixed(2)),
        parseFloat((item['proxy']['profit'] / totalProfit * 100).toFixed(2))
      ]


    },
    chartOptions () {
      let self = this
      return {
        legend: {
          show: true,
          position: 'bottom',
          fontSize: '14px',
          fontFamily: 'Montserrat',
        },
        colors: [
          this.chartColors.donut.series1,
          this.chartColors.donut.series2,
          this.chartColors.donut.series3,
        ],
        dataLabels: {
          enabled: true,
          formatter(val) {
            // eslint-disable-next-line radix
            return `${parseFloat(val).toFixed(2)}%`
          },
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  fontSize: '2rem',
                  fontFamily: 'Montserrat',
                },
                value: {
                  fontSize: '1rem',
                  fontFamily: 'Montserrat',
                  formatter(val) {
                    // eslint-disable-next-line radix
                    return `${parseFloat(val).toFixed(2)}%`
                  },
                },
                total: {
                  show: true,
                  fontSize: '1.5rem',
                  label: 'Estimated profit',
                  formatter() {
                    return self.currencyFormat(self.totalProfit)
                  },
                },
              },
            },
          },
        },
        labels: ['VPS', 'Live stream', 'Proxy'],
        responsive: [
          {
            breakpoint: 992,
            options: {
              chart: {
                height: 380,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
          {
            breakpoint: 576,
            options: {
              chart: {
                height: 320,
              },
              plotOptions: {
                pie: {
                  donut: {
                    labels: {
                      show: true,
                      name: {
                        fontSize: '1.5rem',
                      },
                      value: {
                        fontSize: '1rem',
                      },
                      total: {
                        fontSize: '1.5rem',
                      },
                    },
                  },
                },
              },
              legend: {
                show: true,
              },
            },
          },
        ],
      }
    },
    totalProfit () {
      return (this.data?.vps?.profit + this.data?.livestream?.profit + this.data?.proxy?.profit) || 0
    }
  },
  data () {
    return {
      chartColors: {
        column: {
          series1: '#826af9',
          series2: '#d2b0ff',
          bg: '#f8d3ff',
        },
        success: {
          shade_100: '#7eefc7',
          shade_200: '#06774f',
        },
        donut: {
          series1: '#00b805',
          series2: '#00d4bd',
          series3: '#6b98f8',
          series4: '#2b9bf4',
          series5: '#FFA1A1',
        },
        area: {
          series3: '#a4f8cd',
          series2: '#60f2ca',
          series1: '#2bdac7',
        },
      }
    }
  }
}
</script>
