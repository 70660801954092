<template>
  <section class="invoice-preview-wrapper">
    <b-row
      class="invoice-preview"
    >

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-card
          no-body
          class="invoice-preview-card pb-3"
        >
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">

            <b-row class="invoice-spacing mt-0">

              <!-- Header: Left Content -->
              <b-col cols="12" sm="8">
                <div class="logo-wrapper">
                  <b-img
                      style="width: 36px; height: 36px"
                      :src="$themeConfig.app.appLogoImage"
                      alt="logo"
                  />
                  <h3 class="text-primary invoice-logo">
                    MMO VPS
                  </h3>
                </div>
              </b-col>

              <!-- Header: Right Content -->
              <b-col cols="12" sm="4" v-if="invoiceDetail['id'] !== undefined" class="">
                <div class="d-flex d-inline justify-content-between">
                  <strong class="font-medium-3 align-self-center">Mã hoá đơn:</strong>
                  <strong class="text-danger font-large-2">#{{ invoiceDetail['id'] }}</strong>
                </div>
                <hr/>
                <div class="d-flex d-inline justify-content-between">
                  <span>
                    Ngày tạo hoá đơn:
                  </span>
                  <strong class="ml-e">
                    {{ invoiceDetail['created_at']}}
                  </strong>
                </div>
                <div
                    v-if="!['Finished', 'Canceled'].includes(invoiceDetail['status'])"
                    class="invoice-date-wrapper justify-content-between">
                  <span>
                    Hạn thanh toán:
                  </span>
                  <strong class="ml-3">
                    {{ invoiceDetail['expired_at']}}
                  </strong>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Invoice Client & Payment Details -->
          <b-card-body
              class="invoice-padding pt-0"
          >
            <b-row class="invoice-spacing">
              <!-- Col: Invoice To -->
              <b-col
                  cols="12"
                  xl="6"
                  class="p-0"
              >
                <h6 class="mb-2">
                  Bên nhận:
                </h6>
                <h6 class="mb-25">
                  Nguyễn Văn Viên
                </h6>
                <p class="card-text mb-25">
                  <font-awesome-icon :icon="['fas', 'globe']" class="mr-1"/>
                  <a href="https://viennv.dev" target="_blank">https://viennv.dev</a>
                </p>
                <p class="card-text mb-25">
                  <font-awesome-icon :icon="['fas', 'envelope']" class="mr-1"/>
                  <span>me@viennv.dev</span>
                </p>
                <p class="card-text mb-25">
                  <font-awesome-icon :icon="['fas', 'phone']" class="mr-1"/>
                  <span>+84989621896</span>
                </p>
              </b-col>

              <!-- Col: Payment Details -->
              <b-col
                  xl="6"
                  cols="12"
                  class="p-0 mt-xl-0 mt-2"
              >
                <div>
                  <div>
                    <h6 class="mb-2">
                      Bên thanh toán:
                    </h6>
                    <h6 class="mb-25">
                      <text-loader :is-loading="isDataLoading">
                        {{ invoiceDetail['user']['full_name'] }}
                      </text-loader>
                    </h6>
                    <p class="card-text mb-25">
                      <font-awesome-icon :icon="['fas', 'user']" class="mr-1"/>
                      <span class="d-flex d-inline-flex">
                        <text-loader :is-loading="isDataLoading">
                          <a :href="`${invoiceDetail['user']['facebook_url']}`" target="_blank">{{ invoiceDetail['user']['username'] }}</a>
                        </text-loader>
                      </span>
                    </p>
                    <p class="card-text mb-25">
                      <font-awesome-icon :icon="['fas', 'envelope']" class="mr-1"/>
                      <span class="d-flex d-inline-flex">
                        <text-loader :is-loading="isDataLoading">
                          <span>{{ invoiceDetail['user']['email'] }}</span>
                        </text-loader>
                      </span>
                    </p>
                    <p class="card-text mb-25">
                      <font-awesome-icon :icon="['fas', 'phone']" class="mr-1"/>
                      <span class="d-flex d-inline-flex">
                        <text-loader :is-loading="isDataLoading">
                          <span>{{ invoiceDetail['user']['phone_number'] }}</span>
                        </text-loader>
                      </span>
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Invoice Description: Table -->
          <invoice-topup v-if="invoiceDetail['type'] === 'Topup'"
                         :invoice-id="invoiceDetail['id']"
                         :items="invoiceDetail['items']"
                         :is-loading="isDataLoading"
          />
          <invoice-renew v-else
              :invoice-id="invoiceDetail['id']"
              :items="invoiceDetail['items']"
              :is-loading="isDataLoading"
          />

          <!-- Invoice Description: Total -->
          <b-card-body class="invoice-padding pb-0">
            <b-row>

              <!-- Col: Sales Persion -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-0 mt-3"
                order="2"
                order-md="1"
              >
              </b-col>

              <!-- Col: Total -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-6 d-flex justify-content-end"
                order="1"
                order-md="2"
              >
                <div class="invoice-total-wrapper">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Tổng cộng:
                    </p>
                    <p class="invoice-total-amount">
                      {{ currencyFormat(totalPayment) }}
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Chiết khấu:
                    </p>
                    <p class="invoice-total-amount">
                      {{ currencyFormat(0) }}
                    </p>
                  </div>
                  <hr class="my-50">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      <span>Thành tiền:</span>
                    </p>
                    <p class="invoice-total-amount text-danger">
                      {{ currencyFormat(totalPayment) }}
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <div v-if="![null, undefined, ''].includes(invoiceDetail['note'])">
            <hr class="invoice-spacing"/>
            <div class="mb-0">
              <strong>Ghi chú: </strong> <span class="font-italic">{{ invoiceDetail['note'] }}</span>
            </div>
          </div>
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>
          <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="flat-primary"
              class="pl-0"
              to="/invoices"
          >
            <span>
              <feather-icon
                  icon="ArrowLeftIcon"
                  class="mr-50"
              />
              <strong class="align-middle">Quay lại</strong>
            </span>
          </b-button>
          <skeleton-loader v-if="invoiceDetail['id'] !== undefined" :is-loading="isDataLoading">
            <div class="d-flex justify-content-between">
              <div class="d-flex align-items-center">
                <strong class="">
                  Trạng thái
                </strong>
              </div>
              <b-badge
                  :variant="`light-${invoiceStatus(invoiceDetail['status'])['color']}`"
                  class="badge-glow p-1"
              >
                <div class="d-flex align-items-center justify-content-center">
                  <feather-icon
                      :icon="invoiceStatus(invoiceDetail['status'])['icon']"
                      class="mr-50"
                      style="width: 17px; height: 17px;"
                  />
                  <span>
                  {{ invoiceStatus(invoiceDetail['status'])['text'] }}
              </span>
                </div>
              </b-badge>
            </div>
          </skeleton-loader>
        </b-card>
        <b-card v-if="!['Finished', 'Canceled'].includes(invoiceDetail['status'])">
          <skeleton-loader :is-loading="isDataLoading">
            <b-card-text>
              <div class="d-flex justify-content-between">
                <strong>Thành tiền</strong>
              </div>
            </b-card-text>
            <b-card-text class="text-success text-right font-medium-5 font-weight-bolder">
              {{ currencyFormat(totalPayment) }}
            </b-card-text>
          </skeleton-loader>
        </b-card>
        <b-card v-if="!['Finished', 'Canceled'].includes(invoiceDetail['status'])">
          <skeleton-loader :is-loading="isDataLoading">
            <strong>Mã khuyến mại</strong>
            <custom-input
                class="mt-1"
                label-button="Áp dụng"
                placeholder="Nhập vào mã khuyến mại"
            />
          </skeleton-loader>
        </b-card>
        <b-card v-if="invoiceDetail['id'] !== undefined">
          <strong>Phương thức thanh toán</strong>
          <custom-select
              display-field="short_name"
              :options="paymentMethodFilter"
              v-model="invoiceDetail['payment_detail_id']"
              placeholder="Chọn phương thức thanh toán"
          />
          <div v-if="invoiceDetail['payment_detail_id'] !== null">
            <div v-if="currentPaymentMethod['type'] === 'Balance'">
              <table class="w-100">
                <tr>
                  <td>Số dư tài khoản:</td>
                  <td class="text-right text-danger"><strong>{{ currencyFormat(currentBalance(invoiceDetail['status'])['currentBalance']) }}</strong></td>
                </tr>
                <tr>
                  <td>Số dư còn lại:</td>
                  <td class="text-right text-success"><strong>{{ currencyFormat(currentBalance(invoiceDetail['status'])['remainingBalance']) }}</strong></td>
                </tr>
              </table>
            </div>
            <div v-else>
              <table class="w-100">
                <tr>
                  <td colspan="2">
                    <div class="text-uppercase"><strong>{{ currentPaymentMethod['short_name'] }}</strong></div>
                  </td>
                </tr>
                <tr>
                  <td>Số tài khoản:</td>
                  <td>
                    <div class="d-flex justify-content-end gap-1">
                      <strong>{{ currentPaymentMethod['account_number'] }}</strong>
                      <i class="far fa-qrcode cursor-pointer text-info" v-b-tooltip:hover.top.v-info="`Hiển thị QR Code`" @click="isShowQRCode = !isShowQRCode"/>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Chủ tài khoản:</td>
                  <td class="text-right"><strong>{{ currentPaymentMethod['holder'] }}</strong></td>
                </tr>
                <tr>
                  <td>Nội dung chuyển khoản: </td>
                  <td class="text-right text-danger"><strong>{{ `HD ${this.invoiceDetail['id']}` }}</strong></td>
                </tr>
                <tr>
                  <td>Số tiền: </td>
                  <td class="text-right text-success"><strong>{{ currencyFormat(totalPayment) }}</strong></td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                </tr>
              </table>
              <img v-if="!['Finished', 'Canceled'].includes(invoiceDetail['status']) && isShowQRCode"
                   class="mt-1 w-100 h-100"
                   :src="vietQR(invoiceDetail['payment_detail_id'], totalPayment, `HD ${invoiceDetail['id']}`)"
                   alt="payment method"
                   style="width: 100px; height: 50px;"/>
            </div>
          </div>
        </b-card>
        <b-card>
          <template v-if="!['Finished', 'Canceled'].includes(invoiceDetail['status'])">
            <!-- Button: Send Invoice -->
            <skeleton-loader :is-loading="isDataLoading"
                             v-if="[null, undefined].includes(invoiceDetail['id'])">
              <LoadingButton
                  class="mb-75"
                  :is-block="true"
                  icon="fas fa-paper-plane"
                  variant="success"
                  :is-loading="isCreateInvoiceButtonLoading"
                  :is-disable="isCreateInvoiceButtonLoading"
                  @click="createInvoice"
                  label="Thanh toán"
              />
            </skeleton-loader>
            <skeleton-loader :is-loading="isDataLoading"
                             v-else>
              <LoadingButton
                  class="mb-75"
                  :is-block="true"
                  icon="fas fa-check"
                  variant="success"
                  :is-loading="isCreateInvoiceButtonLoading"
                  :is-disable="isCreateInvoiceButtonLoading"
                  @click="paymentInvoice"
                  label="Xác nhận"
              />
            </skeleton-loader>
            <skeleton-loader :is-loading="isDataLoading"
                             v-if="invoiceDetail['id'] !== undefined">
              <LoadingButton
                  class="mb-75"
                  :is-block="true"
                  variant="secondary"
                  icon="fas fa-cancel"
                  :is-loading="isCancelInvoiceButtonLoading"
                  :is-disable="isCancelInvoiceButtonLoading"
                  @click="cancelInvoice"
                  label="Huỷ bỏ"
              />
            </skeleton-loader>
          </template>

          <!-- Button: Print -->
          <skeleton-loader :is-loading="isDataLoading">
            <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                block
                @click="printInvoice"
            >
              <i class="fas fa-print mr-50"/>
              <span>In hoá đơn</span>
            </b-button>
          </skeleton-loader>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>

import {
  VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import InvoiceRenew from './type/InvoiceRenew.vue'
import InvoiceTopup from "@/views/pages/invoice/type/InvoiceTopup.vue";

import CustomInput from "@/views/components/form/CustomInput.vue"
import LoadingButton from "@/views/components/LoadingButton.vue";
import SkeletonLoader from "@/views/components/loader/SkeletonLoader.vue";
import TextLoader from "@/views/components/loader/TextLoader.vue";
import CustomSelect from "@/views/components/form/CustomSelect.vue";

import inputHelper from "@/mixins/utils/inputHelper";
import dataObjectList from "../../../mixins/utils/dataObjectList";
import dataRequester from "@/mixins/utils/dataRequester";

import cloudVpsService from "@/mixins/utils/axios/cloudVps.service";
import {$themeConfig} from "@themeConfig";
import {mainStore} from "@/store/pinia/main.store";
import invoiceService from "@/mixins/utils/axios/invoice.service";
import proxyService from "@/mixins/utils/axios/proxy.service";
import publicService from "@/mixins/utils/axios/public.service";

export default {
  name: 'InvoiceDetail',
  mixins: [inputHelper, dataObjectList, dataRequester],
  components: {
    InvoiceRenew,
    CustomInput,
    LoadingButton,
    SkeletonLoader,
    TextLoader,
    InvoiceTopup,
    CustomSelect
  },
  computed: {
    $themeConfig() {
      return $themeConfig
    },
    invoiceStatus: function () {
      return (status) => {
        switch (status) {
          case 'Unpaid':
            return {
              color: 'warning',
              text: 'Chưa thanh toán',
              icon: 'AlertCircleIcon'
            }
          case 'Pending':
            return {
              color: 'info',
              text: 'Chờ xác nhận',
              icon: 'ClockIcon'
            }
          case 'Finished':
            return {
              color: 'success',
              text: 'Hoàn thành',
              icon: 'CheckCircleIcon'
            }
          case 'Canceled':
            return {
              color: 'secondary',
              text: 'Đã huỷ',
              icon: 'XCircleIcon'
            }
          default:
            return {
              color: 'secondary',
              text: 'Chưa xác định',
              icon: 'HelpCircleIcon'
            }
        }
      }
    },
    totalPayment: function () {
      if (['Finished', 'Canceled'].includes(this.invoiceDetail['status']) && this.invoiceDetail['id'] !== undefined) {
        return this.invoiceDetail['total_amount'] || 0
      } else {
        return this.invoiceDetail['items'].map(x => x['price'] * (x['period'] || 1)).reduce((a, b) => {return a+b}, 0)
      }
    },
    confirmButton: function () {
      switch(this.paymentButtonName) {
        case 'payment':
          return {
            label: 'Thanh toán',
            variant: 'success',
            function: this.createInvoice
          }
        case 'confirm':
          return {
            label: 'Xác nhận',
            variant: 'success',
            function: function () {}
          }
        default:
          return {
            label: 'Unknown',
            variant: 'secondary'
          }
      }
    },
    paymentMethodFilter () {
      if (this.invoiceDetail['type'] === 'Topup') {
        return this.paymentMethodList.filter(x => x['type'] !== 'Balance')
      } else {
        return this.paymentMethodList
      }
    },
    currentPaymentMethod () {
      return this.paymentMethodList.find(x => x['id'] === this.invoiceDetail['payment_detail_id'])
    },
    vietQR () {
      const self = this
      return function (paymentDetailId, amount, content) {
        const paymentMethod = self.currentPaymentMethod
        if (paymentMethod === null) {
          return null
        }
        return `https://img.vietqr.io/image/${paymentMethod['short_name']}-${paymentMethod['account_number']}-zMyI4c2.png?amount=${amount}&addInfo=${content}&accountName=${paymentMethod['holder']}`
      }
    },
    currentBalance () {
      return (invoiceDetailStatus) => {
        if (invoiceDetailStatus === 'Unpaid') {
          return {
            currentBalance: this.invoiceDetail['user']['balance'],
            remainingBalance: this.invoiceDetail['user']['balance'] - this.totalPayment
          }
        } else {
          return {
            currentBalance: this.invoiceDetail['current_balance'],
            remainingBalance: this.invoiceDetail['current_balance'] - this.totalPayment
          }
        }
      }
    }
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data () {
    return {
      invoiceType: undefined,
      invoiceDetail: {
        items: [],
        user: {},
        payment: {}
      },
      serviceIds: [],
      isDataLoading: false,
      isCreateInvoiceButtonLoading: false,
      isCancelInvoiceButtonLoading: false,
      isShowQRCode: false,
      paymentButtonName: 'payment',
      category: '',
      period: 1,
      paymentMethodList: []
    }
  },
  created() {
    this.invoiceType = this.$route.params['type']
    this.serviceIds = [this.$route.query['id']].flat().map(x => parseInt(x))
    let invoiceId = this.$route.params['invoiceId']
    this.category = this.$route.query['category']
    this.period = this.$route.query['period']
    if (invoiceId !== undefined) {
      this.getInvoiceDetail(invoiceId)
    } else {
      if (this.category === 'vps') {
        this.getCloudVpsList(this.serviceIds)
      } else if (this.category === 'proxy') {
        this.getProxyList(this.serviceIds)
      }
    }
    this.getPaymentMethodList()
  },
  methods: {
    printInvoice() {
      window.print()
    },
    getCloudVpsList(serviceIds) {
      let self = this
      this.isDataLoading = true
      self.runApi(cloudVpsService.listAllActiveCloudVPS(serviceIds), (res) => {
        self.invoiceDetail['items'] = res.data.map(x => {
          return {
            ...x,
            period: this.period || 1,
            price: x['price'] + x['addon'].map(x => x['price'] * x['amount']).reduce((accumulator, currentValue) => accumulator + currentValue, 0)
          }
        }).sort((a, b) => a['end_date'].localeCompare(b['end_date']));
        self.invoiceDetail['user'] = res.data[0]['owner']
      }, (err) => {

      }, () => {
        self.isDataLoading = false
      })
    },
    getProxyList(serviceIds) {
      let self = this
      this.isDataLoading = true
      self.runApi(proxyService.getProxyServiceList(), (res) => {
        self.invoiceDetail['items'] = res.data.filter(x => serviceIds.includes(x['id'])).map(x => {
          return {
            ...x,
            period: this.period || 1,
            price: x['plan']['price']
          }
        }).sort((a, b) => a['end_date'].localeCompare(b['end_date']))
        self.invoiceDetail['user'] = res.data[0]['owner']
      }, (err) => {

      }, () => {
        self.isDataLoading = false
      })
    },

    getInvoiceDetail(invoiceId) {
      let self = this
      self.isDataLoading = true
      this.runApi(invoiceService.getInvoiceDetail(invoiceId), (res) => {
        self.invoiceDetail = {...res.data}
        self.invoiceType = 'renew'
      }, undefined, function () {
        self.isDataLoading = false
      })
    },
    createInvoice() {
      let self = this
      this.isCreateInvoiceButtonLoading = true
      this.runApi(invoiceService.createInvoice({
        user_uuid: self.invoiceDetail['items'][0]['owner']['uuid'],
        payment_detail_id: undefined,
        type: self.$route.params['type'],
        category: self.$route.query['category'],
        items: self.invoiceDetail['items'].map(x => {
          return {
            id: x['id'],
            period: x['period'],
            note: x['note'],
            type: this.category
          }
        }),
      }), (res) => {
        window.location.replace(`/invoice/detail/${res.data['invoice_id']}`)
      },function () {
        self.isCreateInvoiceButtonLoading = false
      })
    },
    paymentInvoice() {
      let self = this
      this.isCreateInvoiceButtonLoading = true
      this.runApi(invoiceService.paymentInvoice({
        invoice_id: self.invoiceDetail['id'],
        payment_detail_id: self.invoiceDetail['payment_detail_id']
      }), function () {
        self.invoiceDetail['status'] = 'Finished'
        mainStore().levBalanceChange(self.invoiceDetail['total_actual'] * -1)
      }, undefined, function () {
        self.isCreateInvoiceButtonLoading = false
      }, true)
    },
    cancelInvoice() {
      let self = this
      this.isCancelInvoiceButtonLoading = true
      this.runApi(invoiceService.cancelInvoice({
        invoice_id: self.invoiceDetail['id']
      }), function () {
        self.invoiceDetail['status'] = 'Canceled'
      }, undefined, function () {
        self.isCancelInvoiceButtonLoading = false
      }, true)
    },
    getPaymentMethodList () {
      this.runApi(publicService.getPaymentMethodList(), (res) => {
        this.paymentMethodList = res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice";
</style>

<style lang="scss">
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
              > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
